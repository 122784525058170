<template>
	<div class='row flex-nowrap skeleton-container'>
		<div class='col'>
			<div class="a-trips__main a-trips__main-transfers">
				<div class='a-trips__main-transfers-wrap'>
					<p class='a-trips__main-transfers-title'><span class='skeleton' :style='`width: 172px; height: 25px`'></span></p>
					<span class='skeleton' :style='`width: 100%; height: 460px`'></span>
				</div>

				<div class='a-trips-content'>
					<div v-for='el in 2' :key='el'>
						<div class='a-card'>
							<div class='t-card-wrap'>
								<div class='t-card-container'>
									<div class='t-card-image'>
										<span class='skeleton' :style='`width: 100%; height: 100%;`'></span>
									</div>
									<div class='t-card-content'>
										<p class='t-card-title'><span class='skeleton' :style='`width: 184px; height: 22px; margin-bottom: 20px;`'></span></p>
										<ul class='a-list t-card-list' v-for='el in 2' :key='el'>
											<li class='a-list-item'>
												<span class='skeleton' :style='`width: 264px; height: 14px`'></span>
											</li>
											<li class='a-list-item'>
												<span class='skeleton' :style='`width: 168px; height: 14px`'></span>
											</li>
										</ul>
									</div>
								</div>
								<div class='t-card-action'>
									<div class='t-card-action-total'>
										<p class='a-card-text'><span class='skeleton' :style='`width: 65px; height: 14px; margin-bottom: 8px`'></span></p>
										<p class='a-card-total'><span class='skeleton' :style='`width: 130px; height: 25px`'></span></p>
									</div>
									<div class='t-card-action-group-buttons t-card-action-skeleton'>
										<span class='skeleton' :style='`width: 100%; height: 60px`'></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {

	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-trips__main-transfers {
		.t-card-action-skeleton {
			@include maxw( $grid-breakpoints-sm ) {
				.skeleton {
					height: 47px !important;
				}
			}
		}
	}
</style>

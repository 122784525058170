<template>
	<div>
		<div class='a-trips'>
			<div class='app-container app-container-fluid a-container'>
				<div class="a-trips__container">
					<trips-header @click='edit = $event' :progress='getProgress'>
						<template v-slot:status>
							<p class='a-trips__header-title bold' v-if='!lastResult'>{{$t('account.search.download_results')}}</p>
							<p class='a-trips__header-title' v-else-if='initialItems.length === 0'>{{$t('account.search.total_number_of_options')}}: <span>(0)</span></p>
							<p class='a-trips__header-title bold' v-else>{{$t('account.search.choose_want.transfer')}}</p>
						</template>
						<template v-slot:title>
							<p class='a-trips__header-title'>{{source.transferFrom.name}} - {{source.transferTo.name}}</p>
						</template>
						<template v-slot:info>
							<p class='a-trips__header-text'>
								{{$moment(source.dateFrom).format("DD/MM/YYYY")}}{{source.dateTo ? ` - ${$moment(source.dateTo).format("DD/MM/YYYY")}` : ""}} • {{title}}
							</p>
						</template>
					</trips-header>

					<trips-preloader
						name='transfers'
						:animationDisabled='lastResult'
						:title='!lastResult ? $t("account.search.preloader.transfers.checking") : $t("account.search.preloader.transfers.no_available")'
						:text='!lastResult ? `${$t("account.search.preloader.we_check_hundreds_of_options")}...` : $t("account.search.preloader.we_could_not_find_the_results")'
						v-if='!progress.some(() => true) || initialItems.length === 0'
					>
						<template v-slot:list v-if='lastResult'>
							<v-button small color='green white--text' :to="$i18nRoute({ name: 'transfers' })">{{$t('account.search.change_search')}}</v-button>
							<ul class='a-trips-search-list'>
								<li class='a-trips-search-list-item'>{{$t('account.search.try_choose_alternate_dates')}}</li>
							</ul>
						</template>
					</trips-preloader>

					<trips-skeleton-card
						v-else-if='loading'
					/>

					<template v-else>
						<div class='row flex-nowrap'>
							<div class='col'>
								<div class="a-trips__main a-trips__main-transfers">
<!--									<div class='a-trips__main-transfers-wrap' v-if='originLocation && destinationLocation'>-->
<!--										<p class='a-trips__main-transfers-title'>{{$t("account.search.transfers.route_map")}}</p>-->

<!--										<div class='a-trips__main-transfers-map'>-->
<!--											<MapboxDirections-->
<!--												:originLocation='originLocation'-->
<!--												:destinationLocation='destinationLocation'-->
<!--											/>-->

<!--											<div class="a-trips__main-transfers-more-page mobile-show"-->
<!--												@click="scrollFix('#a-trips-content')"-->
<!--											>-->
<!--												<div class='a-trips__main-transfers-more-page-button green-persian white&#45;&#45;text'>-->
<!--													<v-icon icon='arrow-down' size='12' />-->
<!--												</div>-->
<!--											</div>-->
<!--										</div>-->
<!--									</div>-->

									<div class='a-trips-content' id='a-trips-content'>
										<div v-for='(item, i) in paginationItems' :key='i'>
											<trips-card
												:item='item'
												@open-transfer='openDetails(item)'
												:check_availability='check_availability'
												@update-comparison='check_availability = $event'
											/>
										</div>

										<v-pagination
											:total='initialItems.length'
											:page='page'
											:perPage='perPage'
											@update-pagination='page = $event'
											v-if='initialItems.length'
										/>

										<trips-preloader
											name='transfers'
											:animationDisabled='true'
											:title='$t("account.search.preloader.transfers.no_found")'
											v-if='paginationItems.length === 0'
										/>
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>

		<trips-edit
			:title='$t("account.search.title.transfers")'
			v-model='edit'
			@input='edit = $event'
		>
			<template>
				<a-home-transfers :edit='true' @update='edit = false, getTransferSearch()' />
			</template>
		</trips-edit>

		<trips-check-transfer
			:details='active_card'
			v-model='card_details'
			@close-dialog='card_details = false'
		/>

		<trips-comparison
			:check_availability='check_availability'
			@clear-comparison='check_availability = {}'
			nameRoute='comparison-transfers'
		/>
		<trips-time @update-search='transferSearch'
			link='transfers'
			:title='$t("account.search.transfers.transfer")'
			:text='$t("account.search.transfers.transfers")'
			:edit="edit"
		/>
	</div>
</template>

<script>
	import TripsHeader from '@/views/account/search/components/header';
	import TripsPreloader from '@/views/account/search/components/preloader';
	import TripsSkeletonCard from '@/views/account/search/transfers/skeleton-card';
	import TripsCard from '@/views/account/search/transfers/card';
	import TripsEdit from '@/views/account/search/components/edit';
	import AHomeTransfers from "@/views/account/home/transfers";
	import TripsTime from '@/views/account/search/components/time';
	import TripsComparison from '@/views/account/search/components/comparison';
	import { getTransferSearch, comparisonCheckAvailability, transferSearch } from '@/services/request';
	// import MapboxDirections from "@/components/Mapbox/MapboxDirections";
	import TripsCheckTransfer from '@/views/account/offers/transfers';
	import { i18n } from '@/plugins/i18n';

	export default {
		data: () => ({
			setTimeout: null,
			loading: false,
			initialItems: [],
			page: 1,
			perPage: 10,
			lastResult: false,
			progress: [],
			edit: false,
			check_availability: {},
			active_card: null,
			card_details: false,
			originLocation: null,
			destinationLocation: null
		}),
		components: {
			TripsHeader,
			TripsPreloader,
			TripsSkeletonCard,
			TripsCard,
			TripsEdit,
			AHomeTransfers,
			TripsTime,
			TripsComparison,
			// MapboxDirections,
			TripsCheckTransfer
		},
		computed: {
			getProgress() {
				if(!this.progress) return;
				return this.progress.filter(el => el === true).length * 100 / this.progress.length;
			},
			source() {
				if(!this.$route.query.source) return;
				return JSON.parse(this.$route.query.source);
			},
			title() {
				let totalTourist = this.source.adults + this.source.childrenCount;
				return `${totalTourist} ${this.$helpers.declOfNum(totalTourist, i18n.t('account.declOfNum.tourists'))}`
			},
			paginationItems() {
				return this.initialItems.slice(this.page === 1 ? 0 : this.perPage * (this.page - 1), this.perPage * this.page);
			}
		},
		created() {
			this.getTransferSearch();
			this.comparisonCheckAvailability();
		},
		methods: {
			getTransferSearch(loading) {
				this.loading = loading ?? !(this.progress.some(() => true) && this.initialItems.length);

				getTransferSearch(this.$route.params.storageId).then(res => {
					if(res.data !== null) {
						this.initialItems = res.data.offers;

						let route = res.data.route;
						this.originLocation = route.from.longitude ? [route.from.longitude, route.from.latitude] : null;
						this.destinationLocation = route.to.longitude ? [route.to.longitude, route.to.latitude] : null;

						this.lastResult = res.data.lastResult;
						this.progress = res.data.progress;

						if(!this.lastResult) {
							this.setTimeout = setTimeout(() => this.getTransferSearch(), 2500);
						}
						if(this.progress.some(() => true) && this.initialItems.length) {
							setTimeout(() => this.loading = false, 5000);
						}
					} else {
						this.initialItems = [];
						this.lastResult = true;
						this.progress = [];
					}
				})
			},
			openDetails(item) {
				this.card_details = true;
				this.active_card = item;
			},
			comparisonCheckAvailability() {
				comparisonCheckAvailability({
					type: 'transfer',
					orderId: this.$route.params.orderId,
					storageId: this.$route.params.storageId
				}).then(res => this.check_availability = res.data ?? {});
			},
			scrollFix(hashbang) {
				const container = document.querySelector(hashbang).offsetTop;
				const header = document.querySelector('.a-header').offsetHeight;

				window.scrollTo({
					top: container + header,
					behavior: 'smooth'
				})
			},
			transferSearch() {
				if(this.$store.state.notification.isActive) this.$store.commit('notification/clearMessage');
				this.loading = true;

				transferSearch(this.source).then(res => {
					if(this.$route.params.storageId === res.data.storageId) {
						this.getTransferSearch(true);
					} else {
						this.$router.push({
							name: "transfers-search",
							params: {orderId: res.data.orderId, storageId: res.data.storageId, lang: this.$route.params.lang},
							query: {source: JSON.stringify(this.form), tripName: this.tripName}
						})
					}
					localStorage.setItem('local_search_data', JSON.stringify({...this.local_search_data, transfers: this.form}));
				})
			}
		},
		watch: {
			"$route.params.storageId"() {
				this.edit = false;
				this.initialItems = [];
				this.page = 1;
				this.lastResult = false;
				this.progress = [];
				this.active_card = null;
				this.card_details = false;
				this.originLocation = null;
				this.destinationLocation = null;

				this.getTransferSearch();
				this.comparisonCheckAvailability();
			}
		},
		beforeDestroy() {
			clearTimeout(this.setTimeout)
		}
	}
</script>

<style lang="scss">
	.a-trips__main {
		&-transfers {
			padding-left: 0;
			max-width: 1110px;
			margin: auto;
			&-wrap {
				padding-top: 20px;
				padding-bottom: 20px;
				.mapboxgl-map {
					border-radius: 10px;
					overflow: hidden;
				}
			}
			&-title {
				font-size: 22px;
				font-weight: 700;
				margin-bottom: 20px;
			}
			&-more-page {
				content: '';
				position: absolute;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 1;
				height: 100px;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
				display: grid;
				align-items: center;
				justify-content: center;
				&-button {
					width: 40px;
					height: 40px;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
</style>
